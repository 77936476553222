// Dependencies
import { createSlice, createAction } from '@reduxjs/toolkit';
import { differenceInCalendarYears, differenceInYears, subYears } from 'date-fns';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { Trip } from '../objects/trip';
import { Traveler } from '../objects/traveler';
import { TripState } from '../objects/tripState';
import { TravelerGroup } from '../objects/travelerGroup';
import { TripPayment } from '../objects/tripPayment';
import { Invoice } from '../objects/invoice';
import Folder from '../../folder/objects/folderType';
import { PaymentRemark } from '../../payment/object/paymentRemark';
import { Payment } from '../../payment/object/payment';
import { TripVersion } from '../objects/tripVersion';

const toggleCompositionDialog = createAction('ACCOMMODATION_TOGGLE_COMPOSITION_DIALOG');

export const adultTraveler: Traveler = {
    address: '',
    address_details: '',
    birth_date: subYears(new Date(), 30),
    city: '',
    country: '',
    email: '',
    first_name: '',
    gender: 'M',
    id: -1,
    identity_doc: 'PASSPORT',
    identity_doc_country_emission: null,
    identity_doc_expiration: null,
    identity_doc_number: null,
    last_name: '',
    loyalty_cards: [],
    middle_name: '',
    nationality: null,
    phone_country_code: '',
    postal_code: '',
    room_index: null,
    title: 'MR',
    traveler_relationship: 'FAMILY',
    trip_reason: 'VACATION',
    weight: null,
    phone_number: ''
};

export const childTraveler: Traveler = {
    address: '',
    address_details: '',
    birth_date: subYears(new Date(), 5),
    city: '',
    country: '',
    email: '',
    first_name: '',
    gender: 'M',
    id: -1,
    identity_doc: 'PASSPORT',
    identity_doc_country_emission: null,
    identity_doc_expiration: null,
    identity_doc_number: null,
    last_name: '',
    loyalty_cards: [],
    middle_name: '',
    nationality: null,
    phone_country_code: '',
    postal_code: '',
    room_index: null,
    title: 'MR',
    traveler_relationship: 'FAMILY',
    trip_reason: 'VACATION',
    weight: null,
    phone_number: null
};

const group: TravelerGroup = {
    default: true,
    id: -1,
    lead_traveler: null,
    lead_traveler_info: null,
    travelers: [],
    travelers_list: [],
    trip: null,
    trip_version: null,
    user: 0
};

const initialState: TripState = {
    active_page: 1,
    adults_cpy: [],
    adults:  1,
    children_cpy: [],
    children: 0,
    default_groups_cpy: [],
    default_groups: [{...group, travelers_list: [{...adultTraveler}]}],
    groups: [],
    old_payment_done: [],
    payment_done: [],
    payment_remarks: [],
    total_accommodation: 0,
    total_flight: 0,
    total_manual: 0,
    total: 0,
    trip: null,
    unaffected_traveler: 0,
    invoices: {state: 'loading'},
    trip_list: null,
    trip_list_nb: null,
    trip_list_loading: false,
    trip_list_search: null,
    trip_list_search_prev: null,
    trip_list_ordering: "-trip_reference",
    trip_list_ordering_prev: "-trip_reference",
    invoiceDeletedManualProducts: [],
    tabs_is_visible: false
};

export const tripSlice = createSlice({
    name: 'trip',
    initialState,
    reducers: {
        addRoom: (state) => {
            const default_groups: TravelerGroup[] = state.default_groups;
            default_groups.push({...group, id: (default_groups.length + 1)*-1, travelers_list: [{...adultTraveler}] });
            state.default_groups = default_groups;
        },
        addTraveler: (state, action: PayloadAction<{type:string, room_index:number}>) => {
            const { type, room_index } = action.payload;
            const default_groups: TravelerGroup[] = state.default_groups;
            default_groups[room_index].travelers_list.push(type === 'adult' ? adultTraveler : childTraveler);
            state.default_groups = default_groups;
        },
        deleteRoom: (state, action: PayloadAction<number>) => {
            const default_groups: TravelerGroup[] = state.default_groups;
            state.default_groups = default_groups.filter((group, index) => index !== action.payload);
        },
        deleteTraveler: (state, action: PayloadAction<{type:string, room_index:number}>) => {
            const { type, room_index } = action.payload;
            const default_groups: TravelerGroup[] = state.default_groups;
            const index = default_groups[room_index].travelers_list.findIndex((traveler) => {
                return (
                    type === 'adult' ? differenceInYears(new Date(), new Date(traveler.birth_date)) > 18 : differenceInYears(new Date(), new Date(traveler.birth_date)) < 18
                );
            });
            default_groups[room_index].travelers_list.splice(index, 1);
            state.default_groups = default_groups;
        },
        addPaymentDone: (
            state,
            action: PayloadAction<TripPayment>
        ): void => {
            state.trip?.payments_done.push(action.payload);
        },
        addPaymentRemark: (state, action: PayloadAction<PaymentRemark>) => {
            const remark = action.payload;
            const copy_payment_remarks = [...state.payment_remarks];
            copy_payment_remarks.push(remark);
            state.payment_remarks = copy_payment_remarks;
        },
        addInvoice: (
            state,
            action: PayloadAction<Invoice>
        ): void => {
            if (state.invoices.state === 'success') {
                state.invoices.data.push(action.payload);
            }
        },
        changeActivePage: (state, action: PayloadAction<number>) => {
            state.active_page = action.payload;
        },
        changePaymentDone: (
            state,
            action: PayloadAction<TripPayment[]>
        ): void => {
            while(state && state.trip && state.trip.payments_done && state.trip.payments_done.length > 0) {
                state.trip?.payments_done.pop();
            }
            action.payload.forEach((payment)=>
                state.trip?.payments_done.push(payment));
        },
        deleteManualProductFromInvoice: (
            state,
            action: PayloadAction<number>
        ): void => {
            state.invoiceDeletedManualProducts.push(action.payload);
        },
        setInvoices: (
            state,
            action: PayloadAction<TripState['invoices']>
        ) => {
            state.invoices = action.payload;
        },
        setInvoiceDeletedManualProducts: (
            state,
            action: PayloadAction<TripState['invoiceDeletedManualProducts']>
        ): void => {
            state.invoiceDeletedManualProducts = action.payload;
        },
        editChildAge: (state, action: PayloadAction<{room_index: number, traveler_index: number, value: number}>) => {
            const {room_index, traveler_index, value} = action.payload;
            const default_groups: TravelerGroup[] = state.default_groups;
            const birth_date = subYears(new Date(), value);
            default_groups[room_index].travelers_list[traveler_index].birth_date = birth_date;
            state.default_groups = default_groups;
        },
        setTotals: (state, action: PayloadAction<{total: number, total_accommodation: number, total_manual: number}>) => {
            const { total, total_accommodation, total_manual } = action.payload;
            state.total = total;
            state.total_accommodation = total_accommodation;
            if (total_manual !== undefined) {
                state.total_manual = total_manual;
            }
        },
        setTravelersGroup: (state, action: PayloadAction<TravelerGroup[]>) => {
            state.groups = action.payload;
        },
        updateTravelersGroupById: (state, action: PayloadAction<TravelerGroup>) => {
            const groups = [...state.groups];
            for (let i = 0; i < groups.length; i++) {
                if (groups[i].id === action.payload.id) {
                    groups[i] = action.payload;
                }
            }
            state.groups = groups;
        },
        updateTravelersInformation: (state, action) => {
            const groups = [...state.groups];
            action.payload.map(passenger => {
                const groupIndex = groups.findIndex(group => group.travelers.includes(passenger.id));
                  if (groupIndex !== -1) {
                      const group = {...state.groups[groupIndex]};
                      const travelers_list = [...group.travelers_list];
                      const travelerIndex = travelers_list.findIndex(traveler => traveler.id === passenger.id);
                      if (travelerIndex !== -1) {
                          travelers_list[travelerIndex] = passenger;
                          group.travelers_list = travelers_list;
                          groups[groupIndex] = group;
                      }
                  }
            });
            state.groups = groups;
        },
        updateTravelerInGroup: (state, action: PayloadAction<Traveler>) => {
            const groups = [...state.groups];
            const groupIndex = groups.findIndex(group => group.travelers.includes(action.payload.id));
            if (groupIndex !== -1) {
                const group = {...state.groups[groupIndex]};
                const travelers_list = [...group.travelers_list];
                const travelerIndex = travelers_list.findIndex(traveler => traveler.id === action.payload.id);
                if (travelerIndex !== -1) {
                    travelers_list[travelerIndex] = action.payload;
                    group.travelers_list = travelers_list;
                    groups[groupIndex] = group;
                }
            }
            state.groups = groups;
        },
        setTravelersGroupWithIndex: (state, action: PayloadAction<{traveler: Traveler}>) => {
            if (differenceInYears(new Date(), new Date(action.payload.traveler.birth_date)) >= 18) {
                state.adults_cpy = state.adults_cpy.map((item) => {
                    if (item.id === action.payload.traveler.id) {
                        return action.payload.traveler;
                    }
                    return item;
                });
            } else {
                state.children_cpy = state.children_cpy.map((item) => {
                    if (item.id === action.payload.traveler.id) {
                        return action.payload.traveler;
                    }
                    return item;
                });
            }
            const groups = state.groups.filter((item) => {
                const ids = item.travelers_list.map((traveler) => traveler.id);
                return ids.includes(action.payload.traveler.id);
            });
            for (const group of groups) {
                group.travelers_list = group.travelers_list.map((traveler) => {
                    if (traveler.id === action.payload.traveler.id) {
                        return action.payload.traveler;
                    }
                    return traveler;
                });
                group.travelers = group.travelers_list.map((item) => item.id);
            }
        },
        setTrip: (state, action: PayloadAction<Trip>) => {
            state.trip = action.payload;
        },
        setTripData: (state, action: PayloadAction<TripVersion>) => {
            const trip:Trip = Object.assign({}, state.trip);
            const copy_data = <TripVersion[]>[];
            trip.data.map((data) => {
                if (data.id === action.payload.id) {
                    copy_data.push(action.payload);
                } else {
                    copy_data.push(data);
                }
            });
            trip.data = copy_data;
            state.trip = trip;
        },
        setTripList: (state, action: PayloadAction<{tripList: Folder[], count: number, loading: boolean}>) => {
            const { tripList, count, loading } = action.payload;
            state.trip_list = tripList;
            state.trip_list_nb = count;
            state.trip_list_loading = loading;
        },
        SetTripListLoader: (state, action: PayloadAction<boolean>) => {
            state.trip_list_loading = action.payload;
        },
        toggleTravelerCpy: (state, action: PayloadAction<{traveler: Traveler, index: number}>) => {
            const { traveler, index } = action.payload;
            const default_groups_cpy = [...state.default_groups_cpy];
            const group = {...default_groups_cpy[index]};
            if (group.travelers.includes(traveler.id)) {
                group.travelers = group.travelers.filter(group_traveler => group_traveler !== traveler.id);
                group.travelers_list = group.travelers_list.filter(group_traveler => group_traveler.id !== traveler.id);
            } else {
                group.travelers.push(traveler.id);
                group.travelers_list.push({...traveler});
            }
            default_groups_cpy[index] = group;
            state.default_groups_cpy = default_groups_cpy;
        },
        updateAdultsCpy: (state, action: PayloadAction<number>) => {
            let adults_cpy = [...state.adults_cpy];
            const default_groups_cpy = [...state.default_groups_cpy];
            const adults_cpy_length = state.adults_cpy.length;
            const diff = action.payload - adults_cpy_length;
            if (adults_cpy_length < action.payload) {
                for (let i = 0; i < diff; i++) {
                    adults_cpy.push({...adultTraveler, id: -1 * Math.floor(Math.random() * 1000)});
                }
            } else {
                const delete_adults = adults_cpy.slice(action.payload);
                delete_adults.map(adult => {
                    default_groups_cpy.map(room => {
                        room.travelers = room.travelers.filter(traveler => traveler !== adult.id);
                        room.travelers_list = room.travelers_list.filter(traveler => traveler.id !== adult.id);
                    });
                });
                adults_cpy = adults_cpy.slice(0, action.payload);
            }
            state.adults_cpy = adults_cpy;
            state.default_groups_cpy = default_groups_cpy;
        },
        updateChildrenCpy: (state, action: PayloadAction<number>) => {
            let children_cpy = [...state.children_cpy];
            const default_groups_cpy = [...state.default_groups_cpy];
            const children_cpy_length = state.children_cpy.length;
            const diff = action.payload - children_cpy_length;
            if (children_cpy_length < action.payload) {
                for (let i = 0; i < diff; i++) {
                    children_cpy.push({...childTraveler, id: -1 * Math.floor(Math.random() * 1000)});
                }
            } else {
                const delete_children = children_cpy.slice(action.payload);
                delete_children.map(child => {
                    default_groups_cpy.map(room => {
                        room.travelers = room.travelers.filter(traveler => traveler !== child.id);
                        room.travelers_list = room.travelers_list.filter(traveler => traveler.id !== child.id);
                    });
                });
                children_cpy = children_cpy.slice(0, action.payload);
            }
            state.children_cpy = children_cpy;
            state.default_groups_cpy = default_groups_cpy;
        },
        updateChildCpyAge: (state, action: PayloadAction<{child_index: number, age: number}>) => {
            const { child_index, age } = action.payload;
            const children_cpy = [...state.children_cpy];
            const child = {...children_cpy[child_index]};
            child.birth_date = subYears(new Date(), age);
            children_cpy[child_index] = child;
            state.children_cpy = children_cpy;
        },
        updateDefaultGroupsCpy: (state, action: PayloadAction<number>) => {
            let default_groups_cpy = [...state.default_groups_cpy];
            const default_groups_cpy_length = state.default_groups_cpy.length;
            const diff = action.payload - default_groups_cpy_length;
            if (default_groups_cpy_length < action.payload) {
                for (let i = 0; i < diff; i++) {
                    default_groups_cpy.push({...group});
                }
            } else {
                default_groups_cpy = default_groups_cpy.slice(0, action.payload);
            }
            state.default_groups_cpy = default_groups_cpy;
        },
        updateInvoice: (
            state,
            action: PayloadAction<Invoice>
        ): void => {
            if (state.invoices.state === 'success') {
                state.invoices.data = state.invoices.data.map((invoice) => {
                    if (invoice.id === action.payload.id) {
                        return action.payload;
                    }
                    return invoice;
                });
            }
        },
        updateTravelersGroup: (state, action: PayloadAction<{traveler: Traveler, index: number}>) => {
            const groups =  state.default_groups;
            const group = groups[action.payload.index];
            const list = group?.travelers_list;
            const travelerToUpdate =  group?.travelers_list?.find((item)=>(item.id === action.payload.traveler.id));
            if (travelerToUpdate){
                const indexToSplice = list?.indexOf(travelerToUpdate);
                if (indexToSplice)
                    group?.travelers_list?.splice(indexToSplice,1,action.payload.traveler);
            }
            if (group)
                state.default_groups.splice(action.payload.index,1,group);
        },
        changePayRemarkType: (state, action: PayloadAction<{remark_index: number, new_type: string}>) => {
            const { remark_index, new_type } = action.payload;
            const copy_payment_remarks = [...state.payment_remarks];
            const copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.payment_type = new_type;
            copy_payment_remarks[remark_index] = copy_remark;
            state.payment_remarks = copy_payment_remarks;
        },
        changePayRemarkDescription: (state, action: PayloadAction<{remark_index: number, new_description: string}>) => {
            const { remark_index, new_description } = action.payload;
            const copy_payment_remarks = [...state.payment_remarks];
            const copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.description = new_description;
            copy_payment_remarks[remark_index] = copy_remark;
            state.payment_remarks = copy_payment_remarks;
        },
        changePayRemarkIdentifier: (state, action: PayloadAction<{remark_index: number, new_identifier: string}>) => {
            const { remark_index, new_identifier } = action.payload;
            const copy_payment_remarks = [...state.payment_remarks];
            const copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.identifier = new_identifier;
            copy_payment_remarks[remark_index] = copy_remark;
            state.payment_remarks = copy_payment_remarks;
        },
        changePayRemarkValue: (state, action: PayloadAction<{remark_index: number, amount: number}>) => {
            const { remark_index, amount } = action.payload;
            const copy_payment_remarks = [...state.payment_remarks];
            const copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.amount = amount;
            copy_payment_remarks[remark_index] = copy_remark;
            state.payment_remarks = copy_payment_remarks;
        },
        setPaymentDate: (state, action: PayloadAction<{remark_index: number, value: Date}>) => {
            const { remark_index, value } = action.payload;
            const copy_payment_remarks = [...state.payment_remarks];
            const copy_remark = Object.assign({}, copy_payment_remarks[remark_index]);
            copy_remark.done_date = value;
            copy_payment_remarks[remark_index] = copy_remark;
            state.payment_remarks = copy_payment_remarks;
        },
        removePnrRemark: (state, action: PayloadAction<number>) => {
            const copy_payment_remarks = [...state.payment_remarks];
            copy_payment_remarks.splice(action.payload, 1);
            state.payment_remarks = copy_payment_remarks;
        },
        setPaymentDone: (state, action: PayloadAction<TripPayment[]>) => {
            state.payment_done = action.payload;
            state.old_payment_done = action.payload;
        },
        setTripSearch: (state, action: PayloadAction<string>) => {
            state.trip_list_search = action.payload;
        },
        emptyPaymentRemarks: (state) =>{
            state.payment_remarks = [];
        },
        tabsIsVisible: (state, action: PayloadAction<boolean>) => {
            state.tabs_is_visible = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(toggleCompositionDialog, state => {
            const adults_cpy: Traveler[] = [];
            state.adults.map(adult => adults_cpy.push({...adult}));
            const children_cpy: Traveler[] = [];
            state.children.map(child => children_cpy.push({...child}));
            const default_groups_cpy: TravelerGroup[] = [];
            state.default_groups.map(group => {
                const group_cpy = {...group};
                group_cpy.travelers = [...group.travelers];
                group_cpy.travelers_list = [];
                group.travelers_list.map(traveler => group_cpy.travelers_list.push({...traveler}));
                default_groups_cpy.push(group_cpy);
            });
            state.adults_cpy = adults_cpy;
            state.children_cpy = children_cpy;
            state.default_groups_cpy = default_groups_cpy;
        });
    }
});

export const {
    addRoom,
    addTraveler,
    addPaymentDone,
    addPaymentRemark,
    addInvoice,
    changeActivePage,
    changePaymentDone,
    deleteManualProductFromInvoice,
    deleteRoom,
    deleteTraveler,
    editChildAge,
    setTotals,
    setTravelersGroup,
    updateTravelersGroupById,
    updateTravelersInformation,
    updateTravelerInGroup,
    setTrip,
    setTripData,
    setTripList,
    setTripSearch,
    SetTripListLoader,
    toggleTravelerCpy,
    updateAdultsCpy,
    updateChildrenCpy,
    updateChildCpyAge,
    updateDefaultGroupsCpy,
    updateInvoice,
    setTravelersGroupWithIndex,
    updateTravelersGroup,
    setInvoices,
    setInvoiceDeletedManualProducts,
    changePayRemarkType,
    changePayRemarkDescription,
    changePayRemarkIdentifier,
    changePayRemarkValue,
    setPaymentDate,
    setPaymentDone,
    emptyPaymentRemarks,
    tabsIsVisible,
    removePnrRemark
} = tripSlice.actions;

export default tripSlice.reducer;