import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
// Types
import { Steps } from "../objects/steps";
import { CartState } from "../objects/cartState";
import { Currency } from "../../currency/objects/currency";
import { ManualProduct } from "../../cart/objects/manualProduct";
import { AccommodationProductRoom } from "../../accommodation/objects/accommodationProductRoom";
import { setEvent } from "../../event/redux/reducer";
import { utcToZonedTime } from "date-fns-tz";

const initialState: CartState = {
  automatic_products_open: false,
  blocs: [{accommodation: null, contract: null, end_date: null, room: null, room_nb: 1, start_date: null}],
  blocs_create: false,
  on_request_product: null,
  manual_products: [],
  manual_product_open: false,
  manual_product_id: null,
  manual_product_name: '',
  manual_product_type: 16,
  manual_product_start_date: null,
  manual_product_end_date: null,
  manual_product_provider: null,
  manual_product_purchase_price: '',
  manual_product_purchase_currency: null,
  manual_product_margin_type: 'PER',
  manual_product_margin: '',
  manual_product_tva: '',
  manual_product_tva_is_margin_tva: false,
  manual_product_description: '',
  manual_product_note: '',
  roomChangePrices: {},
  step: Steps.CART,
  selected_room_modification_fees: null,
  linked_product_id: null,
  comment_dialog: null,
  companion_dialog: null,
  name_provided_later: false,
  amwc_edit: false,
  filter_first_name: '',
  filter_last_name: '',
  filter_check_in: null,
  filter_check_out: null,
  filter_category: '',
  filter_additional_product: '',
  table_first_date: null,
  table_first_date_offset: 0,
  displayed_accommodation: 0,
  amwc_columns: [
    {
      field: 'information_passenger',
      label: 'information-passenger',
      colspan: 3,
      active: true,
      order: 1,
      color: '#F9E7E7',
      movable: true,
      minWidth: 200,
      children: ['companion', 'last-name', 'first-name']
    },
    {
      field: 'period',
      label: 'period',
      colspan: 2,
      active: true,
      order: 2,
      color: '#D9E4F9',
      movable: true,
      minWidth: 200,
      children: ['check-in', 'check-out']
    },
    {
      field: 'room',
      label: 'room',
      colspan: 2,
      active: true,
      order: 3,
      color: '#F1F8F4',
      movable: true,
      minWidth: 200,
      children: ['category', 'occupation']
    },
    {
      field: 'additional_products',
      label: 'additional-products',
      colspan: 1,
      active: true,
      order: 4,
      color: '#FFF8EF',
      movable: true,
      minWidth: 200,
      children: ['name']
    },
    {
      field: 'nights',
      label: 'nights-title',
      colspan: 6,
      active: true,
      order: 5,
      color: '#EFEFFF',
      movable: true,
      minWidth: 100,
      children: ['prev', 'day1', 'day2', 'day3', 'day4', 'next']
    },
    {
      field: 'total',
      label: 'Total',
      colspan: 4,
      active: true,
      order: 6,
      color: '#FFF8EF',
      movable: true,
      minWidth: 100,
      children: ['number-of-nights', 'price-night', 'total', 'extra-price']
    },
    {
      field: 'menu',
      label: '',
      colspan: 1,
      active: true,
      order: 7,
      color: '#b4c6e7',
      movable: false,
      minWidth: 50,
      children: ['menu']
    }
  ],
  add_night_data: null,
  add_remark_booking: null,
  add_comment: null,
  change_companion_data: null,
  open_import_names: false,
  show_amwc_history: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setRoomChangeVariants: (state, action: PayloadAction<{roomId: number, data: CartState['roomChangePrices'][number]}>): void => {
      state.roomChangePrices[action.payload.roomId] = action.payload.data;
    },
    setStep: (state, action: PayloadAction<Steps>) => {
      state.step = action.payload;
    },
    setOnRequestProducts: (state, action) => {
      state.on_request_product = action.payload;
    },
    setManualProducts: (state, action: PayloadAction<ManualProduct[]>) => {
      state.manual_products = action.payload;
    },
    updateManualProductsAfterBooking: (state, action: PayloadAction<ManualProduct[]>) => {
      state.manual_products = state.manual_products.map((item) => {
        const replacement = action.payload.find((product) => product.id === item.id);
        return replacement ?? item;
      });
    },
    addManualProduct: (state, action: PayloadAction<ManualProduct>) => {
      state.manual_products = [...state.manual_products, action.payload];
      state.automatic_products_open = false;
      state.manual_product_open = false;
      state.manual_product_id = null;
      state.manual_product_name = '';
      state.manual_product_type = 16;
      state.manual_product_start_date = null;
      state.manual_product_end_date = null;
      state.manual_product_provider = null;
      state.manual_product_purchase_price = '';
      state.manual_product_purchase_currency = null;
      state.manual_product_margin_type = 'PER';
      state.manual_product_margin = '';
      state.manual_product_tva = '';
      state.manual_product_tva_is_margin_tva = false;
      state.manual_product_description = '';
      state.manual_product_note = '';
    },
    deleteManualProduct: (state, action: PayloadAction<number>) => {
      state.manual_products = [...state.manual_products].filter(product => product.id !== action.payload);
    },
    openManualProduct: (state) => {
      state.manual_product_open = true;
    },
    resetManualProduct: (state) => {
      state.manual_product_open = false;
      state.manual_product_id = null;
      state.manual_product_name = '';
      state.manual_product_type = 16;
      state.manual_product_start_date = null;
      state.manual_product_end_date = null;
      state.manual_product_provider = null;
      state.manual_product_purchase_price = '';
      state.manual_product_purchase_currency = null;
      state.manual_product_margin_type = 'PER';
      state.manual_product_margin = '';
      state.manual_product_tva = '';
      state.manual_product_tva_is_margin_tva = false;
      state.manual_product_description = '';
      state.manual_product_note = '';
      state.linked_product_id = null;
    },
    updateManualProductCart: (state, action: PayloadAction<ManualProduct>) => {
      state.manual_products = [...state.manual_products, action.payload];
    },
    updateManualProductType: (state, action: PayloadAction<11 | 16>) => {
      state.manual_product_type = action.payload;
    },
    updateManualProductName: (state, action: PayloadAction<string>) => {
      state.manual_product_name = action.payload;
    },
    updateManualProductStartDate: (state, action: PayloadAction<Date>) => {
      state.manual_product_start_date = action.payload;
    },
    updateManualProductEndDate: (state, action: PayloadAction<Date>) => {
      state.manual_product_end_date = action.payload;
    },
    updateManualProductPurchasePrice: (state, action: PayloadAction<string>) => {
      state.manual_product_purchase_price = action.payload;
    },
    updateManualProductPurchaseCurrency: (state, action: PayloadAction<Currency>) => {
      state.manual_product_purchase_currency = action.payload;
    },
    updateManualProductMarginType: (state, action: PayloadAction<"FIX" | "PER">) => {
      state.manual_product_margin_type = action.payload;
    },
    updateManualProductMargin: (state, action: PayloadAction<string>) => {
      state.manual_product_margin = action.payload;
    },
    updateManualProductDescription: (state, action: PayloadAction<string>) => {
      state.manual_product_description = action.payload;
    },
    updateManualProductNote: (state, action: PayloadAction<string>) => {
      state.manual_product_note = action.payload;
    },
    toggleBlocAccommodationForm: (state): void => {
      state.blocs_create = !state.blocs_create;
    },
    addBloc: (state) => {
      state.blocs = [...state.blocs, {accommodation: null, contract: null, end_date: state.end_date, room: null, room_nb: 1, start_date: state.start_date}];
    },
    deleteBloc: (state, action: PayloadAction<number>) => {
      const blocs = [...state.blocs];
      blocs.splice(action.payload, 1);
      state.blocs = blocs;
    },
    updateBlocAccommodation: (state, action: PayloadAction<{bloc_index: number, accommodation: null | ManualAccommodation}>) => {
      const { bloc_index, accommodation } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.accommodation = accommodation;
      bloc.contract = null;
      bloc.room = null;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocContract: (state, action: PayloadAction<{bloc_index: number, contract: null | Contract}>) => {
      const { bloc_index, contract } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.contract = contract;
      bloc.room = null;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocEndDate: (state, action: PayloadAction<{bloc_index: number, date: null | Date}>) => {
      const { bloc_index, date } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.end_date = date;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocRoom: (state, action: PayloadAction<{bloc_index: number, room: null | Room}>) => {
      const { bloc_index, room } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.room = room;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocRoomNb: (state, action: PayloadAction<{bloc_index: number, room_nb: string | number}>) => {
      const { bloc_index, room_nb } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.room_nb = room_nb;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocStartDate: (state, action: PayloadAction<{bloc_index: number, date: null | Date}>) => {
      const { bloc_index, date } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.start_date = date;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    toggleAncillaryProductsOpen: (state) => {
      state.automatic_products_open = !state.automatic_products_open;
    },
    setSelectedRoomModificationFees: (state, action: PayloadAction<null | AccommodationProductRoom>) => {
      state.selected_room_modification_fees = action.payload;
    },
    setLinkedProductId: (state, action: PayloadAction<null | number>) => {
      state.linked_product_id = action.payload;
      state.manual_product_open = true;
    },
    setOpenCommentDialog: (state, action: PayloadAction<null | number>) => {
      state.comment_dialog = action.payload;
    },
    setOpenCompanionDialog: (state, action: PayloadAction<null | number>) => {
      state.companion_dialog = action.payload;
    },
    setNameProvidedLater: (state) => {
      state.name_provided_later = !state.name_provided_later;
    },
    setAmwcEdit: (state) => {
      state.amwc_edit = !state.amwc_edit;
    },
    setAmwcColumns: (state, action: PayloadAction<null | number>) => {

    },
    setFilterFirstName: (state, action: PayloadAction<string>) => {
      state.filter_first_name = action.payload;
    },
    setFilterLastName: (state, action: PayloadAction<string>) => {
      state.filter_last_name = action.payload;
    },
    setFilterCheckIn: (state, action: PayloadAction<null | Date>) => {
      state.filter_check_in = action.payload;
    },
    setFilterCheckOut: (state, action: PayloadAction<null | Date>) => {
      state.filter_check_out = action.payload;
    },
    setFilterCategory: (state, action: PayloadAction<string>) => {
      state.filter_category = action.payload;
    },
    setFilterAdditionalProduct: (state, action: PayloadAction<string>) => {
      state.filter_additional_product = action.payload;
    },
    setDisplayedAccommodation: (state, action: PayloadAction<number>) => {
      state.displayed_accommodation = action.payload;
    },
    setTableFirstDateOffset: (state, action: PayloadAction<number>) => {
      if (state.table_first_date_offset === -3) {
        if (action.payload === 1) {
          state.table_first_date_offset = 0;
        }
      } else if (state.table_first_date_offset === 0) {
        if (action.payload === -1) {
          state.table_first_date_offset = -3;
        } else if (action.payload === 1) {
          state.table_first_date_offset = 2;
        }
      } else if (state.table_first_date_offset === 2) {
        if (action.payload === -1) {
          state.table_first_date_offset = 0;
        }
      }
    },
    setAddNight: (state, action) => {
      state.add_night_data = action.payload;
    },
    setAddRemarkBooking: (state, action) => {
      state.add_remark_booking = action.payload;
    },
    setAddComment: (state, action) => {
      state.add_comment = action.payload;
    },
    setChangeCompanionData: (state, action) => {
      state.change_companion_data = action.payload;
    },
    setImportNames: (state) => {
      state.open_import_names = !state.open_import_names;
    },
    setShowAmwcHistory: (state) => {
      state.show_amwc_history = !state.show_amwc_history;
    },
  },
  extraReducers: builder => {
    builder.addCase(setEvent, (state, action) => {
      state.table_first_date = action.payload.event_start_date !== null ? utcToZonedTime(new Date(action.payload.event_start_date), 'Etc/UTC') : null;
    });
  }
});

export const {
  setRoomChangeVariants,
  setStep,
  setOnRequestProducts,
  setManualProducts,
  addManualProduct,
  deleteManualProduct,
  openManualProduct,
  resetManualProduct,
  updateManualProductCart,
  updateManualProductType,
  updateManualProductName,
  updateManualProductStartDate,
  updateManualProductEndDate,
  updateManualProductPurchasePrice,
  updateManualProductPurchaseCurrency,
  updateManualProductMarginType,
  updateManualProductMargin,
  updateManualProductDescription,
  updateManualProductNote,
  toggleBlocAccommodationForm,
  addBloc,
  deleteBloc,
  updateBlocAccommodation,
  updateBlocContract,
  updateBlocEndDate,
  updateBlocRoom,
  updateBlocRoomNb,
  updateBlocStartDate,
  toggleAncillaryProductsOpen,
  setSelectedRoomModificationFees,
  setLinkedProductId,
  setOpenCommentDialog,
  setOpenCompanionDialog,
  setNameProvidedLater,
  setAmwcEdit,
  setAmwcColumns,
  setFilterFirstName,
  setFilterLastName,
  setFilterCheckIn,
  setFilterCheckOut,
  setFilterCategory,
  setFilterAdditionalProduct,
  setDisplayedAccommodation,
  setTableFirstDateOffset,
  setAddNight,
  setAddRemarkBooking,
  setAddComment,
  setChangeCompanionData,
  setImportNames,
  setShowAmwcHistory,
} = cartSlice.actions;

export default cartSlice;